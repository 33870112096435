//module.exports = {
/*
export const Constants = {
    DealerSharedServiceBaseUrl : 'https://avvs0yuvhd.execute-api.us-west-2.amazonaws.com/Stage/',
    DealerServiceBaseUrl : 'https://avvs0yuvhd.execute-api.us-west-2.amazonaws.com/Stage/'
}
*/
// //QA API Endpoints
// export const DealerSharedServiceBaseUrl =  'https://p5al3yy62i.execute-api.us-west-2.amazonaws.com/QA/';
// export const DealerServiceBaseUrl = 'https://061j517483.execute-api.us-west-2.amazonaws.com/QA/'

export const DealerServiceBaseUrl = 'https://dealer-api.dev.dealer.warnerbros.com/'
export const DealerSharedServiceBaseUrl =  'https://shared-api.dev.dealer.warnerbros.com/';
export const OKTA_CLIENT_ID = "0oasttqb8mubyTYM90h7";

export const OKTA_ISSUER_URL = "https://twdev.oktapreview.com/oauth2/default";
export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
export const OKTA_URL = "https://twdev.oktapreview.com";
export const IDLE_TIMEOUT = "60";
export const IDLE_WARN_TIMEOUT = "30";

